<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    :return-value.sync="date"
    persistent
    max-width="320"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :label="label"
        readonly
        v-bind="$attrs"
        :value="dateFormatted"
        v-on="on"
      />
    </template>
    <v-date-picker ref="picker" v-model="date" full-width locale="fr-FR" :max="max" :min="min" class="mt-2">
      <div class="flex-grow-1" />
      <v-btn text small color="primary" @click="modal = false">Annuler</v-btn>
      <v-btn text small color="primary" @click="save">Sauvegarder</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
  import {fromNonRetardToRetardFormat} from '@/utils/date'

  export default {
    name: 'DatePicker',
    props: {
      label: {
        type: String,
        default: ''
      },

      birthdayPicker: {
        type: Boolean
      },

      value: {
        type: String,
        default: null
      }
    },

    data(){
      return {
        modal: false,
        date: null
      }
    },

    computed: {

      max(){
        return this.birthdayPicker ? new Date().toISOString().substr(0, 10) : null
      },

      min(){
        return this.birthdayPicker ? '1901-01-01' : null
      },

      dateFormatted(){
        return this.date ? fromNonRetardToRetardFormat(this.date) : null
      }
    },

    watch: {

      modal(val){
        if(val && this.birthdayPicker){
          this.$nextTick(()=>{this.$refs.picker.activePicker = 'YEAR'})
        }
      },

      value(val){
        if(val && !this.date){
          this.date = val
        }
      }

    },

    methods: {
      save(){
        this.$refs.dialog.save(this.date)
        this.$emit('input', this.date)
      }
    }
  }
</script>
