<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <v-text-field :value="address1" label="Adresse" dense hide-details="auto" class="mb-5" @change="value => {$emit('update:address1', value)}" />
    <v-text-field
      v-model="localZipCode"
      maxlength="25"
      label="Code postal"
      :error="!!error"
      :error-messages="error"
      dense hide-details="auto" class="mb-5"
      @change="value => {$emit('update:zip_code', value)}"
    />
    <v-select
      :value="city"
      label="Ville"
      :items="cities.items"
      :disabled="cities.disabled"
      :loading="cities.loading"
      item-value="insee_code"
      item-text="name"
      dense hide-details="auto" class="mb-5"
      @change="value => {$emit('update:city', value)}"
    />
  </div>
</template>

<script>
  import ZipCodes from '@/api/zipCodes'
  import {debounce} from 'throttle-debounce'

  export default {
    name: 'AppAddressFields',
    // eslint-disable-next-line vue/prop-name-casing
    props: ['address1', 'zip_code', 'city'],

    data(){
      return {
        localZipCode: '',
        cities: {
          disabled: true,
          loading: false,
          items: []
        },
        error: null
      }
    },

    watch: {
      localZipCode: debounce(500, function(val){
        this.fetchCities(val)
      }),

      zip_code(val){
        this.localZipCode = val
      }
    },

    methods: {
      async fetchCities(val){
        if(!val){
          return
        }

        this.cities.loading = true

        let [err, zipCode] = await ZipCodes.get(val)

        this.cities.loading = false

        if(err){
          this.items = []
          this.$emit('update:city', null)
          this.cities.disabled = true
          this.error = 'Code postal invalide.'
          return
        }

        this.cities.items = zipCode.cities
        this.error = null

        if(1 === this.cities.items.length){
          this.$emit('update:city', this.cities.items[0].insee_code)
          return
        }

        this.cities.disabled = false
      }
    }

  }
</script>
