/*
 * Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import api from './api'

/**
 * @deprecated Use zip-codes in address module instead
 */
export default {
  async get(zipCode) {

    const _url = '/v2/zip-codes/' + zipCode

    let [error, data] = await api.get(_url)
        .then(response => {return [null, response.data]})
        .catch(error => {return [error.response, null]})

    return [error, data]
  }

}

