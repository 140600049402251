<template>
  <v-card class="p-rel" :loading="loading">
    <v-btn x-small fab dark color="primary" class="close-modal" @click="$emit('close')">
      <v-icon small color="white">$close</v-icon>
    </v-btn>
    <v-toolbar class="primary white--text d-block d-md-none" max-height="56">
      <v-toolbar-title>{{ patientUid ? 'Modification du patient' : 'Création d\'un patient' }}</v-toolbar-title>
    </v-toolbar>
    <v-row class="ma-0 row-dialog">
      <modals-sidebar :title="patientUid ? 'Modification du patient' : 'Création d\'un patient'" class="d-none d-md-block" />
      <v-col cols="12" md="11" class="pt-2">
        <v-form ref="form" v-model="valid" lazy-validation :disabled="submitting" class="py-3" @submit.prevent="submit">
          <form-category title="Identité" subtitle="Informations du patient" />

          <v-select v-model="model.title" :items="titles" label="Titre" :rules="[rules.required]" dense
                    hide-details="auto"
                    class="mb-5"
          />
          <v-text-field v-model="model.last_name" label="Nom" :rules="[rules.required, rules.minLength(2), rules.maxLength(28)]" dense hide-details="auto"
                        class="mb-5"
          />
          <v-text-field v-model="model.maiden_name" label="Nom de naissance" :rules="[rules.maxLength(28)] " dense
                        hide-details="auto" class="mb-5"
          />
          <v-text-field v-model="model.first_name" label="Prénom" :rules="[rules.required, rules.minLength(2), rules.maxLength(28)]" dense hide-details="auto"
                        class="mb-5"
          />
          <v-select v-model="model.gender" :items="genders" label="Genre" :rules="[rules.required]" dense
                    hide-details="auto" class="mb-5"
          />
          <date-picker v-model="model.birth_date" label="Date de naissance" :birthday-picker="true" dense
                       hide-details="auto" class="mb-5" :rules="[rules.required]"
          />

          <form-category title="Contact" subtitle="Informations de contact du patient" class="mt-8" />

          <v-text-field v-model="model.email" label="Adresse e-mail" :rules="[rules.email]" dense hide-details="auto"
                        class="mb-5"
          />
          <phone-input ref="ValidInput" v-model="model.phone" />
          <app-address-fields v-bind.sync="model.address" />
          <v-spacer class="mt-2" />
          <v-row class="text-center justify-center">
            <v-btn color="primary" x-small type="submit" :loading="submitting" :disabled="!valid || submitting" class="mt-4">
              Enregistrer
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import AppAddressFields from '@/modules/core/layout/app/AppAddressFields'
  import PhoneInput from '@/modules/core/components/PhoneInput'
  import DatePicker from '@/modules/core/components/DatePicker'
  import {rules} from '@/services/rules'
  import ModalsSidebar from '@/modules/core/modals/ModalsSidebar'
  import * as Patients from '@/modules/patients/api'
  import FormCategory from '@/modules/core/layout/FormCategory'
  import {Patient} from '@/models'
  import {empty} from '@/utils/variables'
  import {pick} from '@/utils/object'

  export default {
    name: 'PatientForm',
    components: {FormCategory, DatePicker, PhoneInput, AppAddressFields, ModalsSidebar},
    props: {
      default: Object,
      patientUid: String
    },
    data() {
      return {
        rules,
        valid: true,
        submitting: false,
        loading: false,
        model: {
          title: '',
          first_name: '',
          last_name: '',
          maiden_name: '',
          gender: '',
          birth_date: null,
          email: null,
          phone: null,
          address: {
            address1: null,
            zip_code: null,
            city: null
          }
        },
        genders: [
          {text: 'Homme', value: 'male'},
          {text: 'Femme', value: 'female'}
        ],
        titles: [
          {text: 'Monsieur', value: 'M.'},
          {text: 'Madame', value: 'Mme.'}
        ]
      }
    },
    computed: {
      patient() {
        return Patient.get(this.patientUid)
      }
    },
    mounted() {
      if (this.patientUid) {
        this.loading = true
        this.loading = false
        if(empty(this.patient.zip_code)) {
          this.patient.zip_code = { name: null }
        }

        this.model = {
          title: this.patient.title,
          first_name: this.patient.first_name,
          last_name: this.patient.last_name,
          maiden_name: this.patient.maiden_name,
          gender: this.patient.gender,
          birth_date: this.patient.birth_date,
          email: this.patient.email,
          phone: this.patient.phone,
          address: {
            address1: this.patient.address ? this.patient.address.address1 : null,
            zip_code: this.patient.address && this.patient.address.zip_code ? this.patient.address.zip_code.name : null,
            city: this.patient.address ? this.patient.address.city : null
          }
        }
      }
    },
    methods: {
      async submit() {
        if (this.$refs.form.validate()) {
          this.submitting = true
          if (this.patientUid) {
            Patients.update(this.patientUid, this.model)
              .then(({data}) => {
                this.patient.$mutate(patient => {
                  // TODO Wait for a PUT /V3/users/:user
                  data.address = {
                    ...pick(data, ['address1', 'address2', 'address3', 'latitude', 'longitude', 'location']),
                    city: data.city,
                    zip_code: data.zip_code
                  }
                  delete data.city
                  delete data.zip_code
                  Object.assign(patient, data)
                })
                this.$emit('close')
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'success',
                  title: 'Patient édité avec succès',
                  subtitle: 'Le patient a été édité avec succès.'
                })
              })
              .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e)
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'error',
                  title: 'Une erreur est survenue',
                  subtitle: 'Le patient n\'a pas pu être édité.'
                })
              })
              .finally(() => {
                this.submitting = false
              })
          } else {
            Patients.create(this.model)
              .then((patientData) => {
                this.$store.commit('user/lists/addPatient', patientData.data)
                Patient.save(patientData.data)
                this.$emit('close')
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'success',
                  title: 'Patient créé',
                  subtitle: 'Le patient a été créé avec succès.'
                })
              })
              .catch(() => {
                this.$root.$emit('snackbar:open', {
                  snackbar: ()=> import('@/modules/core/layout/snackbars/Snackbar'),
                  type: 'error',
                  title: 'Une erreur est survenue',
                  subtitle: 'Le patient n\'a pas pu être créé.'
                })
              })
              .finally(() => {
                this.submitting = false
              })
          }
        }
      }
    }
  }
</script>
